const domain = window.location.hostname;
const mutuDomain = "panel.mutuahorro.com";
const cobruDomain = "panel.cobru.co";
const ezyDomain = "panel2.ezytransfer.co";
const colocaDomain = 'panel.colocapayments.com'
const pagaHoyDomain = 'panel.pagahoy.com'
const trixelDomain = 'panel.trixel.co'



let variablesDomain = {
    classNameColor: "green-600",
    colorPrimary: "#1CCE28",
    brand: null
};


switch (domain) {
    case cobruDomain:
        variablesDomain = {
            classNameColor: "green-600",
            colorPrimary: "#1CCE28",
            brand: null

        };
        break;
    case mutuDomain:
        variablesDomain = {
            classNameColor: "mainColorMutu",
            colorPrimary: "#3D4BCD",
            brand: null

        };
        break;
    case ezyDomain:
        variablesDomain = {
            classNameColor: "mainColorEzy",
            colorPrimary: "#2e86c1",
            brand: 'EZYTRANSFER'

        };
        break;
    case colocaDomain:
        variablesDomain = {
            classNameColor: "mainColorColoca",
            colorPrimary: "#00B022",
            brand: null
        };
        break;
    case pagaHoyDomain:
        variablesDomain = {
            classNameColor: "mainColorPagaHoy",
            colorPrimary: "#f84365",
            brand: 'PAGAHOY'
        };
        break;
    case trixelDomain:
        variablesDomain = {
            classNameColor: "mainColorTrixel",
            colorPrimary: "#272727",
            brand: null
        };
        break;
    default:

        break;
}

export default variablesDomain;